<template>
  <v-sheet outlined width="600" class="pa-6 my-4">
    <v-form @submit.prevent="updateItem" ref="form">
      <v-row>
        <v-col>
          <label class="v-label v-label--active theme--light text-body-2"
            >Type of transaction</label
          >
          <v-radio-group row v-model="form.operationType">
            <v-radio label="Credit" value="Credit"></v-radio>
            <v-radio label="Debit" value="Debit"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-text-field
            label="Amount"
            v-model="form.amount"
            :rules="[(v) => !!v || 'Required']"
            type="text"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-textarea
        v-model="form.description"
        clearable
        clear-icon="mdi-close-circle"
        label="Description"
        auto-grow
        rows="2"
        :rules="[
          (v) => !!v || 'Required',
          (v) => v.length >= 3 || 'Should contains at least 3 symbols',
          (v) => v.length <= 40 || 'Should contains maximum 40 symbols',
        ]"
      ></v-textarea>
      <v-alert v-if="hasErrors" type="error" elevation="0" outlined>
        <div v-for="(error, key) in errors" :key="key">
          {{ prepareErrorMessage(error, key) }}
        </div>
      </v-alert>
      <div class="d-flex mt-3">
        <v-btn type="submit" color="primary" rounded small> Confirm </v-btn>
      </div>
    </v-form>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { CREATE_TRANSACTION } from "@/store/modules/account/actions";
export default {
  name: "AccountTransactionForm",
  props: {
    accountId: {
      required: true,
    },
  },
  data: function () {
    return {
      dataLoading: false,
      form: {
        amount: null,
        description: "",
        operationType: "Credit",
      },
      errors: {},
    };
  },
  computed: {
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
  },
  methods: {
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        const { form } = this;
        this.$store
          .dispatch("account/" + CREATE_TRANSACTION, {
            transaction: form,
            accountId: this.accountId,
          })
          .then(
            (response) => {
              this.dataLoading = false;
              if (response.result) {
                this.$refs.form.reset();
                this.errors = {};

                this.form.operationType = "Credit";
                this.$emit("submitted");
              } else {
                this.errors = response.details;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
};
</script>
