<template>
  <div>
    <router-link :to="backLink" class="text-body-2 text-decoration-none"
      >Back to Accounts page</router-link
    >
    <h1 class="outline--text">
      {{ pageTitle }}
    </h1>
    <account-transaction-form
      v-if="canManage"
      :account-id="id"
      @submitted="
        fetchTransactions();
        fetchAccount();
      "
    ></account-transaction-form>
    <v-row>
      <v-col>
        <h3>Billing history</h3>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="transactions"
      :items-per-page="pagination.rowPerPage"
      :server-items-length="pagination.rowPerPage"
      disable-pagination
      disable-filtering
      hide-default-footer
      :sort-by.sync="sort.sortBy"
      :sort-desc.sync="sort.desc"
    >
      <template v-slot:item.operationType="{ item }">
        <v-chip
          small
          :color="item.operationType === 'Debit' ? 'red' : 'green'"
          dark
        >
          {{ item.operationType }}
        </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small :color="getStatusClass(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  FETCH_ACCOUNT_TRANSACTIONS,
  FETCH_ACCOUNT,
} from "@/store/modules/account/actions";

import AccountTransactionForm from "@/views/account/item/AccountTransactionForm";
import acl from "@/mixin/acl";
import { RESOURCE_BALANCE_OPERATIONS_MANAGEMENT } from "@/store/modules/admin/getters";
export default {
  name: "AccountTransactionsPage",
  mixins: [acl],
  components: { AccountTransactionForm },
  props: {
    id: {
      required: true,
    },
    name: {
      type: String,
    },
    balance: {
      type: String,
    },
  },
  data() {
    return {
      prevRoute: null,
      isLoading: false,
      transactions: [],
      info: null,
      pagination: {
        page: 1,
        rowPerPage: 20,
      },
      sort: {
        sortBy: "createdAt",
        desc: true,
      },
      count: 0,
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Date",
          value: "createdAt",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Transaction type",
          value: "operationType",
        },
        {
          text: "Transaction method",
          value: "method",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
    };
  },
  computed: {
    canManage: function () {
      return this.hasAccess([RESOURCE_BALANCE_OPERATIONS_MANAGEMENT]);
    },
    pageTitle() {
      if (this.name) {
        return `Balance ${this.name}: ${
          this.balance === "-" ? 0 : this.balance
        } UAH`;
      } else if (this.info) {
        return `Balance ${this.info.name}: ${
          this.info.balance === "-" ? 0 : this.info.balance
        } UAH`;
      } else {
        return "Balance";
      }
    },
    pageCount() {
      return Math.ceil(this.count / this.pagination.rowPerPage);
    },
    limit() {
      return this.pagination.rowPerPage;
    },
    offset() {
      return (this.pagination.page - 1) * this.pagination.rowPerPage;
    },
    formattedSort() {
      let formatted = this.sort.sortBy;
      if (this.sort.desc) {
        return `-${formatted}`;
      }
      return formatted;
    },
    backLink() {
      return this.prevRoute && this.prevRoute.name === "AccountListPage"
        ? this.prevRoute
        : { name: "AccountListPage" };
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.fetchTransactions();
      },
    },
    sort: {
      deep: true,
      handler() {
        this.fetchTransactions();
      },
    },
  },
  methods: {
    fetchTransactions() {
      this.isLoading = true;
      this.$store
        .dispatch(`account/${FETCH_ACCOUNT_TRANSACTIONS}`, {
          accountId: this.id,
          limit: this.limit,
          offset: this.offset,
          sort: this.formattedSort,
        })
        .then(
          (response) => {
            const { count, data } = response;
            this.count = count;
            this.transactions = data;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    fetchAccount() {
      this.isLoading = true;
      this.$store
        .dispatch(`account/${FETCH_ACCOUNT}`, {
          accountId: this.id,
        })
        .then(
          (response) => {
            const { data } = response;
            this.info = data;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getStatusClass(status) {
      let color = "grey";
      switch (status) {
        case "Pending": {
          color = "blue";
          break;
        }
        case "Successful": {
          color = "green";
          break;
        }
        case "Failed": {
          color = "red";
          break;
        }
      }
      return color;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    this.fetchTransactions();
    this.fetchAccount();
  },
};
</script>
